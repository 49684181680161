/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 200,
    });
}

/***
 * GERAL
 ***/
$(function() {

    // menu
    var $win = $(window),
        $menu = $('header nav'),
        $menuToggle = $('header .menuToggle');

    $($menuToggle).click(function(event) {
        event.preventDefault();
        $menu.slideToggle();
        $('html').toggleClass('open-menu');
    });

    if ($win.width() <= 768) {
        $('header nav a').click(function(event) {
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });
    }

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'inline-block');
        }

    });

    setTimeout(function() {
        var $banner = $('.Banner').height();

        $(window).scroll(function () {
            if ($(window).scrollTop() > $banner) {
                $('header').addClass('_fixed');
            } else {
                $('header').removeClass('_fixed');
            }
        });

        if ($(window).scrollTop() > $banner) {
            $('header').addClass('_fixed');
        } else {
            $('header').removeClass('_fixed');
        }
    }, 30);

    // imagesLoaded
    $('body').imagesLoaded().always( function( instance ) {
        $('body').addClass('loaded');
    });

    // wow
    new WOW({
        offset: 200
    }).init();


    // owl carousel
    $('.owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        dots            : false,
        nav             : true,
        navText         : ['<i class="icon-left"></i>','<i class="icon-right"></i>']
    });

    // Galeria
    $('.Galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
            enabled: true
          },
          zoom: {
            enabled: true,
            duration: 300,
            opener: function(element) {
              return element.find('img');
            }
          }
          
        });
    });
    

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1];
            }
        }
    };

    $('.wpcf7-form-control-wrap.assunto input').each(function() {
        var $val = getUrlParameter('assunto');
        if ($val) {
            $(this).val($val);

            setTimeout(function() {
                $('.wpcf7-form-control-wrap.nome input').focus();
            }, 100);
        }
    });

    // Input masks
    $("input.cpf").mask("999.999.999-99",{autoclear: false});
    $("input.cnpj").mask("99.999.999/9999-99",{autoclear: false});
    $("input.data").mask("99/99/9999",{autoclear: false});
    $("input.cep").mask("99999-999",{autoclear: false});
    $("input.hora").mask("99:99",{autoclear: false});
    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 9 9999-999?9",{autoclear: false});
        } else {
            element.mask("(99) 9999-9999?9",{autoclear: false});
        }
    }).trigger('focusout');

    $('form').attr('autocomplete', 'off');

});

$.gmap3(false);